<template>
  <!-- login page  -->
  <v-app v-if="openuploadlayout === false" :style="colorVapp">
    <v-app-bar
      app
      elevation="0"
      color="#FFFFFF"
      style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
    >
      <v-toolbar-title class="pt-1">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="95"
          height="40"
          alt="One Box"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-divider inset vertical></v-divider>
      <v-toolbar-items>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab text v-on="on" small>
              <flag :iso="defaultflag" :squared="false" />
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              @click="changelang(item.data)"
            >
              <v-list-item-action>
                <flag :iso="item.flag" :squared="false" />
              </v-list-item-action>
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-if="loadingpage === true"
    >
      <v-flex lg6 xs11>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="100"
          ></v-progress-circular>
        </div>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-if="accesslinkerror === true"
    >
      <v-flex lg6 xs11>
        <div class="text-center">
          <v-card outlined style="border-radius: 25px">
            <br />
            <v-card-text>
              <v-icon size="100" medium color="red"
                >mdi-folder-remove</v-icon
              >
            </v-card-text>
            <v-card-text v-if="accessexpirederror === true" style="font-size: 20px"
              >{{ $t("sharelinkuploadpage.linkexpired") }} </v-card-text
            >
            <v-card-text v-else-if="accesskeyerror === true" style="font-size: 20px"
              >{{ $t("sharelinkuploadpage.linkploblem") }} </v-card-text
            >
            <v-card-text
              v-else-if="accessnolink === true"
              style="font-size: 20px"
              >
              {{ $t("sharelinkuploadpage.nolink") }}
              </v-card-text
            >
            <v-card-text
              v-else-if="accessdisable === true"
              style="font-size: 20px"
              >
              {{ $t("sharelinkuploadpage.disablelink") }}
              </v-card-text
            >
            <v-card-text v-else style="font-size: 20px"
              >{{ $t("sharelinkuploadpage.linkploblem") }}</v-card-text
            >
            <br />
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <p class="pt-1">
                Powered By &nbsp;
                <img
                  @click.stop="gotohomepage()"
                  class="pt-2"
                  style="vertical-align: text-bottom"
                  src="@/assets/logo_onebox-horizontal-png.png"
                  width="65px"
                  height="35px"
                  alt="One Box2"
                />
              </p>
            </v-card-actions>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      fill-height
      align-center
      justify-center
      v-else
    >
      <v-flex lg6 xs11>
        <div class="text-center">
          <v-card 
            v-if="!loadingpage"
            outlined 
            style="background-color: #FFFFFF"
            class="elevation-2 rounded-xl"
          >
            <br />
            <v-card-text>
              <v-icon size="100" medium color="#FFC107">mdi-folder</v-icon>
            </v-card-text>
            <v-card-text class="text-center" v-if="isExistProfile">
              <h2>{{ $t("username") }} : {{ username_show }}</h2>
            </v-card-text>
            <br />
            <v-layout align-center justify-center>
              <v-row align-items="center" justify="center" 
              no-gutters v-if="!isExistProfile">
              <v-col md="6" lg="8" xl="6" xs="12" sm="8" >
                <v-tabs
                  show-arrows
                  fixed-tabs
                  background-color="primary"
                  dark
                  v-model="step1"
                >
                  <!-- login username -->
                  <v-tab @click="fn_clicktabinfor()" :disabled="isSendOTP && !isTimeout">
                    <v-icon class="mr-1">mdi-account-box</v-icon>
                    Username
                  </v-tab>
                  <!-- login SMS -->
                  <v-tab @click="fn_clicktabphone()">
                    <v-icon class="mr-1">mdi-cellphone</v-icon>
                    SMS
                  </v-tab>
                </v-tabs>
                <div v-if="tab_username">
                  <v-card-text class="text-left">
                    <b>{{ $t("username") }}</b>
                    <v-form ref="usernameform">
                    <v-text-field
                      class="mt-2"
                      outlined
                      solo
                      flat
                      color="#174966"
                      prepend-inner-icon="account_box"
                      dense
                      v-model.trim="username"
                      :error-messages="usernameloginError"
                      @input="fn_tolowercase(), $v.username.$touch()"
                      @blur="$v.username.$touch()"></v-text-field>
                    </v-form>
                    <b>{{ $t("password") }}</b>
                    <v-form ref="passform">
                    <v-text-field
                      class="mt-2"
                      prepend-inner-icon="lock"
                      outlined
                      solo
                      color="#174966"
                      flat
                      dense
                      v-model.trim="password_login"
                      @click:append="showpassword = !showpassword"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :error-messages="passwordloginError"
                      @input="fn_tolowercase(), $v.password_login.$touch()"
                      @blur="$v.password_login.$touch()"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                </div>
                <div v-if="tab_sms">
                  <div v-if="!isSendOTP">
                  <v-card-text class="text-left">
                  <b>{{ $t("loginSMS") }}</b>
                      <v-text-field
                        v-model="phonenumber"
                        class="mt-2"
                        outlined
                        solo
                        hide-details="auto"
                        flat
                        color="#174966"
                        prepend-inner-icon="phone"
                        dense
                        :disabled="loading"
                        :label="$t('phoneNumber')"
                        maxlength="10"></v-text-field>
                  </v-card-text>
                  </div>
                  <div v-else style="display: flex; flex-direction: column; align-items: center;">
                    <h3 class="text-center mt-4 mb-6">{{ $t("verifyOTP") }}</h3>
                    <otp-input
                      class="testotp"
                      ref="otpVerifyRefs"
                      @on-complete="onCompleteOTP"
                      @on-changed="onChangeOTP" 
                      :separateInputClass="otpclass"
                      :autoFocus="false"
                      activeWrapperClass="mai-finished"
                      placeholder=""
                      :isDisabled="isTimeout"
                      :isValid="false"
                      :digits="6"
                      type="text">
                    </otp-input>
                    <v-layout row wrap class="px-7 py-4" style="width: 100%">
                      <v-flex>
                        <div>{{ $t("countDown") }} {{ formattedCountdownLoginSMS }} </div>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex>
                        <span
                        v-if="isTimeout"
                        @click="fn_resendOTP()"
                        style="text-decoration: underline; cursor: pointer;"
                        >
                          {{ $t("resendOTP") }}
                        </span>
                        <span
                        v-else
                        >
                          REF: {{ refCode }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </v-col>
            </v-row>
            </v-layout>
            <v-layout
              lg12
              xs12
              row
              wrap
              align-center
              justify-center
              v-if="!isLogged"
              class="mt-4"
            >
              <v-flex lg3 xs3 style="min-width: 180px;">
                <v-btn
                  v-if="tab_username"
                  block
                  rounded
                  color="primary"
                  class="white--text elevation-0"
                  :disabled="isloading || (username.length === 0 && password_login.length === 0) && !isExistProfile"
                  @click="fn_loginshare()"
                >
                  <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                  {{ isExistProfile ? $t("publicpage.login") : tab_sms ? $t("otp_botonebox.send_otp_btn") : $t("publicpage.login") }}
                  <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                </v-btn>
                <div v-else>
                  <v-btn
                  v-if="!isSendOTP"
                  block
                  rounded
                  color="primary"
                  class="white--text elevation-0"
                  :disabled="isloading || (phonenumber.length === 0) || isSendOTP"
                  @click="fn_sendOTP()"
                >
                  <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                  {{$t("otp_botonebox.send_otp_btn")}}
                  <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                  </v-btn>
                  <v-btn
                  v-else
                  block
                  rounded
                  color="primary"
                  class="white--text elevation-0"
                  :disabled="isTimeout"
                  @click="fn_loginshare()"
                >
                  <!-- @click="$router.push({ name: 'login_sharelink_tokenunavailable', query: { nextUrl: '/public?id=' + $route.query.id } })" -->
                  ยืนยัน OTP 
                  <v-progress-circular v-if="isloading" indeterminate size="18" style="position: absolute; right: 2px" />
                </v-btn>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              lg12
              xs12
              row
              wrap
              align-center
              justify-center
              v-if="!isLogged && isExistProfile"
              class="mt-3"
            >
              <v-flex lg3 xs3 style="min-width: 180px;">
                <v-btn
                  block
                  rounded
                  color="error"
                  class="white--text elevation-0"
                  @click="fn_logout"
                >
                  {{ $t("logout")}}
                </v-btn>
              </v-flex>
            </v-layout>
            <br />
            <br />
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <p class="pt-1">
                Powered By &nbsp;
                <img
                  @click.stop="gotohomepage()"
                  class="pt-2"
                  style="vertical-align: text-bottom"
                  src="@/assets/logo_onebox-horizontal-png.png"
                  width="65px"
                  height="35px"
                  alt="One Box2"
                />
              </p>
            </v-card-actions>
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-app>
  <!-- layout direct upload -->
  <v-app v-else :style="colorVapp" app>
    <!-- toolbbar -->
    <v-app-bar
      app
      elevation="0"
      color="#FFFFFF"
      style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
    >
      <v-toolbar-title class="pt-1">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="95"
          height="40"
          alt="One Box"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-skeleton-loader
        width="200"
        v-if="loading_profile"
        type="list-item-avatar"
      ></v-skeleton-loader>
      <v-toolbar-items
        v-else
        :class="resolutionScreen < 500 ? 'mr-3' : 'mr-4'"
      >
        <v-list-item-avatar
          v-if="dataCitizenProfile.picture == null && resolutionScreen >= 500"
          size="80"
          class="mr-0"
        >
          <v-avatar size="45">
            <img src="@/assets/user.png" alt="John" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-avatar size="80" class="mr-0" v-else-if="resolutionScreen >= 500">
          <v-avatar size="45" style="border: solid 1px #00000061">
            <img
              :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
              alt="John"
            />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{
              $t("default") === "th"
                ? dataCitizenProfile["first_name_th"] +
                    " " +
                    dataCitizenProfile["last_name_th"] || ""
                : dataCitizenProfile["first_name_eng"] +
                    " " +
                    dataCitizenProfile["last_name_eng"] || ""
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-toolbar-items>
      <v-divider inset vertical></v-divider>
      <v-toolbar-items>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab text v-on="on" small>
              <flag :iso="defaultflag" :squared="false" />
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              @click="changelang(item.data)"
            >
              <v-list-item-action>
                <flag :iso="item.flag" :squared="false" />
              </v-list-item-action>
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn 
          icon
          @click="logout()"
        >
          <v-icon>
            mdi-logout
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <!-- ปุ่มอัปโหลดของ mobile -->
    <v-app-bar
      style="position: fixed; bottom:0;  width: 100%; background-color: #e8eff2; height:60px; z-index:9"
      v-if="resolutionScreen === 220"
    >
      <v-btn
        color="red"
        height="45px"
        width="45%"
        outlined
        style="font-size: 16px; font-weight: lighter;"
        :disabled="createprogress"
        @click="cleardata()"
      >
        {{ $t("sharelinkuploadpage.clearfile") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="white--text"
        color="#23526c"
        style="font-size: 16px; font-weight: lighter;"
        height="45px"
        width="45%"
        :disabled="totalstackfile === 0 || filename.length <= 0"
        @click="fnUploadFileShareLink()"
      >
        {{ $t("sharelinkuploadpage.uploadfile") }}
      </v-btn>
    </v-app-bar>
    <!-- content -->
    <v-main>
      <v-container>
        <v-row no-gutters justify="center">
          <!-- จอ lg -->
          <v-card
            v-if="resolutionScreen >= 600"
            style="border-radius: 25px; margin-top:10px"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-3"
          >
            <v-card-text style="font-size: 16px" class="text-right mt-3 pr-12 mb-n8">
              <span>
                <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                <v-icon v-else size="18">mdi-office-building</v-icon>
                <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
              </span>
              <span> | </span>
              <span>
                <v-icon size="18">mdi-account-circle-outline</v-icon>
                <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
              </span>
            </v-card-text>
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("sharelinkuploadpage.savedriveof") }}
                    <span style="color: #386e50; font-weight: 600;"
                      >{{ foldername }}</span
                    >
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="totalstackfile > 0" class="mb-n8">
              <v-layout row wrap justify-center>
                <!-- <v-flex lg3></v-flex> -->
                <v-flex
                  lg12
                  class="text-center mt-12 py-12 mx-11"
                  id="zonefile"
                >
                  <span class="my-12">
                    <v-icon
                      size="40"
                      color="error"
                      class="pl-12 ml-12 mr-n12 mt-n6"
                      @click="fnDeleteStackFile(arraydataupload[0])"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <br />
                    <v-icon size="100" :color="arraydataupload[0].icon[1]">
                      {{ arraydataupload[0].icon[0] }}
                    </v-icon>
                    <br />
                    <span
                      style="font-size:18px"
                      :title="arraydataupload[0].name"
                      >{{ arraydataupload[0].name | subStr }}</span
                    >
                  </span>
                </v-flex>
                <!-- <v-flex lg3></v-flex> -->
                <br />
                <v-flex lg3></v-flex>
                <v-flex lg6 class="mt-5 text-left pr-4 text-center">
                  <span style="font-size:16px">
                    {{ remark}}
                    <br/>
                    <br/>
                    <v-text-field
                      height="35px"
                      class="textFieldClass"
                      v-model.trim="filename"
                      :label="$t('sharelinkuploadpage.filename')"
                      outlined
                      dense
                    ></v-text-field>
                  </span>
                </v-flex>
                <v-flex lg3></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text
              v-else
              style="max-height: auto; height: auto;"
              class="mt-2"
            >
              <v-layout>
                <v-flex lg12 class="px-8">
                  <!-- upload layout -->
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto pb-0 areadrag"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover" class="pt-4 areadrag">
                      <input
                        ref="importdoc"
                        class="d-none areadrag"
                        type="file"
                        @change="fnStackFile"
                        style="display:none;"
                      />
                      <v-img
                        max-width="180"
                        max-height="160"
                        class="mx-auto areadrag"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" class="areadrag">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span id="titleTextUploadsubtitle" class="areadrag">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br class="areadrag" /><br class="areadrag" />
                    </div>
                  </div>
                  <v-layout style="height:75px"> </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout class="mt-n0 mb-10" row justify-center>
                <v-flex lg5></v-flex>
                <v-flex lg2 class="text-center">
                  <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.waitupload") }}</span
                    >
                  </v-chip>
                  <v-chip color="success" v-else-if="statussuccess == true">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="error"
                    v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.notupload") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                </v-flex>
                <v-flex lg5></v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="
                      cleardata(), (percentuploadedallfile = 0), (filename = '')
                    "
                  >
                    {{ $t("sharelinkuploadpage.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0 || filename.length <= 0"
                    @click="fnUploadFileShareLink()"
                  >
                    {{ $t("sharelinkuploadpage.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ md portrait-->
          <v-card
            v-else-if="resolutionScreen === 500 && checkorientation === false"
            style="margin-top: 80px; border-radius: 25px; padding-top: 30px; padding-bottom: 70px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text style="font-size: 16px" class="text-right mt-3 pr-12 mb-n8">
              <span>
                <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                <v-icon v-else size="18">mdi-office-building</v-icon>
                <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
              </span>
              <span> | </span>
              <span>
                <v-icon size="18">mdi-account-circle-outline</v-icon>
                <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
              </span>
            </v-card-text>
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("sharelinkuploadpage.savedriveof") }}
                    <span style="color: #386e50; font-weight: 600;"
                      >{{ foldername }}</span
                    >
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="totalstackfile > 0" class="mb-n8">
              <v-layout row wrap justify-center>
                <v-flex
                  md12
                  class="text-center mt-12 py-12 mx-11"
                  id="zonefile"
                >
                  <span class="my-12">
                    <v-icon
                      size="40"
                      color="error"
                      class="pl-12 ml-12 mr-n12 mt-n6"
                      @click="fnDeleteStackFile(arraydataupload[0])"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <br />
                    <v-icon size="100" :color="arraydataupload[0].icon[1]">
                      {{ arraydataupload[0].icon[0] }}
                    </v-icon>
                    <br />
                    <span
                      style="font-size:18px"
                      :title="arraydataupload[0].name"
                      >{{ arraydataupload[0].name | subStr }}</span
                    >
                  </span>
                </v-flex>
                <br />
                <v-flex md2></v-flex>
                <v-flex md8 class="mt-5 text-left pr-4 text-center">
                  <span style="font-size:16px">
                    {{ remark}}
                    <br/>
                    <br/>
                    <v-text-field
                      height="35px"
                      class="textFieldClass"
                      v-model.trim="filename"
                      :label="$t('sharelinkuploadpage.filename')"
                      outlined
                      dense
                    ></v-text-field>
                  </span>
                </v-flex>
                <v-flex md2></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text
              v-else
              style="max-height: auto; height: auto;"
              class="mt-2"
            >
              <v-layout>
                <v-flex lg12 class="px-8">
                  <!-- upload layout -->
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto pb-0 areadrag"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover" class="pt-4 areadrag">
                      <input
                        ref="importdoc"
                        class="d-none areadrag"
                        type="file"
                        @change="fnStackFile"
                        style="display:none;"
                      />
                      <v-img
                        max-width="180"
                        max-height="160"
                        class="mx-auto areadrag"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span class="areadrag" id="titleTextUploadtitle">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span class="areadrag" id="titleTextUploadsubtitle">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br /><br />
                    </div>
                  </div>
                  <v-layout style="height:75px"> </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout class="mt-n0 mb-10" row justify-center>
                <v-flex lg5></v-flex>
                <v-flex lg2 class="text-center">
                  <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.waitupload") }}</span
                    >
                  </v-chip>
                  <v-chip color="success" v-else-if="statussuccess == true">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="error"
                    v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.notupload") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                </v-flex>
                <v-flex lg5></v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="
                      cleardata(), (percentuploadedallfile = 0), (filename = '')
                    "
                  >
                    {{ $t("sharelinkuploadpage.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0 || filename.length <= 0"
                    @click="fnUploadFileShareLink()"
                  >
                    {{ $t("sharelinkuploadpage.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ md landscape-->
          <v-card
            v-else-if="resolutionScreen === 500 && checkorientation === true"
            style="margin-top: 10px; border-radius: 25px; padding-top: 10px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text style="font-size: 16px" class="text-right mt-3 pr-12 mb-n8">
              <span>
                <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                <v-icon v-else size="18">mdi-office-building</v-icon>
                <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
              </span>
              <span> | </span>
              <span>
                <v-icon size="18">mdi-account-circle-outline</v-icon>
                <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
              </span>
            </v-card-text>
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("sharelinkuploadpage.savedriveof") }}
                    <span style="color: #386e50; font-weight: 600;"
                      >{{ foldername }}</span
                    >
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="totalstackfile > 0" class="mb-n8">
              <v-layout row wrap justify-center>
                <v-flex
                  md12
                  class="text-center mt-12 py-12 mx-11"
                  id="zonefile"
                >
                  <span class="my-12">
                    <v-icon
                      size="40"
                      color="error"
                      class="pl-12 ml-12 mr-n12 mt-n6"
                      @click="fnDeleteStackFile(arraydataupload[0])"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <br />
                    <v-icon size="100" :color="arraydataupload[0].icon[1]">
                      {{ arraydataupload[0].icon[0] }}
                    </v-icon>
                    <br />
                    <span
                      style="font-size:18px"
                      :title="arraydataupload[0].name"
                      >{{ arraydataupload[0].name | subStr }}</span
                    >
                  </span>
                </v-flex>
                <br />
                <v-flex md2></v-flex>
                <v-flex md8 class="mt-5 text-left pr-4 text-center">
                  <span style="font-size:16px">
                    {{ remark }}
                    <br/>
                    <br/>
                    <v-text-field
                      height="35px"
                      class="textFieldClass"
                      v-model.trim="filename"
                      :label="$t('sharelinkuploadpage.filename')"
                      outlined
                      dense
                    ></v-text-field>
                  </span>
                </v-flex>
                <v-flex md2></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text
              v-else
              style="max-height: auto; height: auto;"
              class="mt-2"
            >
              <v-layout>
                <v-flex lg12 class="px-8">
                  <!-- upload layout -->
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto pb-0 areadrag"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover" class="pt-4 areadrag">
                      <input
                        ref="importdoc"
                        class="d-none areadrag"
                        type="file"
                        @change="fnStackFile"
                        style="display:none;"
                      />
                      <v-img
                        max-width="180"
                        max-height="160"
                        class="mx-auto areadrag"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" class="areadrag">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span id="titleTextUploadsubtitle">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br class="areadrag" /><br  class="areadrag" />
                    </div>
                  </div>
                  <v-layout style="height:75px"> </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout class="mt-n0 mb-10" row justify-center>
                <v-flex lg5></v-flex>
                <v-flex lg2 class="text-center">
                  <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.waitupload") }}</span
                    >
                  </v-chip>
                  <v-chip color="success" v-else-if="statussuccess == true">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="error"
                    v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.notupload") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                </v-flex>
                <v-flex lg5></v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="
                      cleardata(), (percentuploadedallfile = 0), (filename = '')
                    "
                  >
                    {{ $t("sharelinkuploadpage.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0 || filename.length <= 0"
                    @click="fnUploadFileShareLink()"
                  >
                    {{ $t("sharelinkuploadpage.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ sm portrait -->
          <v-card
            v-else-if="resolutionScreen === 400 && checkorientation === false"
            style="margin-top: 10px; border-radius: 25px;"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-4 mx-6"
          >
            <v-card-text style="font-size: 16px" class="text-right mt-3 pr-12 mb-n8">
              <span>
                <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                <v-icon v-else size="18">mdi-office-building</v-icon>
                <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
              </span>
              <span> | </span>
              <span>
                <v-icon size="18">mdi-account-circle-outline</v-icon>
                <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
              </span>
            </v-card-text>
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("sharelinkuploadpage.savedriveof") }}
                    <span style="color: #386e50; font-weight: 600;"
                      >{{ foldername }}</span
                    >
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="totalstackfile > 0" class="mb-n8">
              <v-layout row wrap justify-center>
                <v-flex
                  sm12
                  class="text-center mt-12 py-12 mx-11"
                  id="zonefile"
                >
                  <span class="my-12">
                    <v-icon
                      size="40"
                      color="error"
                      class="pl-12 ml-12 mr-n12 mt-n6"
                      @click="fnDeleteStackFile(arraydataupload[0])"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <br />
                    <v-icon size="100" :color="arraydataupload[0].icon[1]">
                      {{ arraydataupload[0].icon[0] }}
                    </v-icon>
                    <br />
                    <span
                      style="font-size:18px"
                      :title="arraydataupload[0].name"
                      >{{ arraydataupload[0].name | subStr }}</span
                    >
                  </span>
                </v-flex>
                <br />
                <v-flex sm2></v-flex>
                <v-flex sm8 class="mt-5 text-left pr-4 text-center">
                  <span style="font-size:16px">
                    {{ remark }}
                    <br/>
                    <br/>
                    <v-text-field
                      height="35px"
                      class="textFieldClass"
                      v-model.trim="filename"
                      :label="$t('sharelinkuploadpage.filename')"
                      outlined
                      dense
                    ></v-text-field>
                  </span>
                </v-flex>
                <v-flex sm2></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text
              v-else
              style="max-height: auto; height: auto;"
              class="mt-2"
            >
              <v-layout>
                <v-flex lg12 class="px-8">
                  <!-- upload layout -->
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto pb-0"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover" class="pt-4">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        style="display:none;"
                      />
                      <v-img
                        max-width="180"
                        max-height="160"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle">{{
                        $t("sharelinkuploadpage.clicktoupload_mobile")
                      }}</span>
                      <br />
                      <span id="titleTextUploadsubtitle">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br /><br />
                    </div>
                  </div>
                  <v-layout style="height:75px"> </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout class="mt-n0 mb-10" row justify-center>
                <v-flex lg5></v-flex>
                <v-flex lg2 class="text-center">
                  <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.waitupload") }}</span
                    >
                  </v-chip>
                  <v-chip color="success" v-else-if="statussuccess == true">
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="error"
                    v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.notupload") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 18px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                </v-flex>
                <v-flex lg5></v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="
                      cleardata(), (percentuploadedallfile = 0), (filename = '')
                    "
                  >
                    {{ $t("sharelinkuploadpage.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0 || filename.length <= 0"
                    @click="fnUploadFileShareLink()"
                  >
                    {{ $t("sharelinkuploadpage.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ sm landscape -->
          <v-card
            v-else-if="resolutionScreen === 400 && checkorientation === true"
            style="margin-top: 2px; border-radius: 25px; overflow:hidden;"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-1"
          >
            <v-layout row wrap style="overflow:hidden;" class="py-8">
              <v-flex sm6>
                <v-card-text v-if="totalstackfile > 0" class="mb-n8">
                  <v-layout row wrap justify-center>
                    <v-flex sm12 class="text-center py-4 mx-11" id="zonefile">
                      <span class="my-12">
                        <v-icon
                          size="40"
                          color="error"
                          class="pl-12 ml-12 mr-n12 mt-n6"
                          @click="fnDeleteStackFile(arraydataupload[0])"
                        >
                          mdi-close-circle-outline
                        </v-icon>
                        <br />
                        <v-icon size="100" :color="arraydataupload[0].icon[1]">
                          {{ arraydataupload[0].icon[0] }}
                        </v-icon>
                        <br />
                        <span
                          style="font-size:18px"
                          :title="arraydataupload[0].name"
                          >{{ arraydataupload[0].name | subStr }}</span
                        >
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-text
                  v-else
                  style="max-height: auto; height: auto;"
                  class="my-n3 mr-n6"
                >
                  <v-layout>
                    <v-flex lg12 class="pl-2">
                      <!-- upload layout -->
                      <div
                        :style="uploadLayout"
                        class="text-center mt-3 ml-6 mr-8"
                        @click="ImportFiles"
                        id="dropzone"
                      >
                        <div id="layouthovermobile">
                          <input
                            ref="importdoc"
                            class="d-none"
                            type="file"
                            @change="fnStackFile"
                            style="display:none"
                          />
                          <v-img
                            max-width="180"
                            max-height="160"
                            class="mx-auto"
                            :src="require('@/assets/img/uploadfiles.png')"
                          />
                          <span
                            id="titleTextUploadtitle"
                            style="font-size:16px;"
                            >{{ $t("sharelinkuploadpage.clicktoupload_mobile") }}</span
                          >
                          <br />
                          <span
                            id="titleTextUploadsubtitle"
                            style="font-size:14px;"
                            >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                          >
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-flex>
              <v-flex sm6>
                <v-card-text style="font-size: 16px" class="mt-3 mb-1">
                  <span>
                    <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                    <v-icon v-else size="18">mdi-office-building</v-icon>
                    <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
                  </span>
                  <span> | </span>
                  <span>
                    <v-icon size="18">mdi-account-circle-outline</v-icon>
                    <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
                  </span>
                </v-card-text>
                <v-card-text class="mt-n8 mb-n8">
                  <v-layout>
                    <v-flex lg12>
                      <p style="font-size: 16px">
                        {{ $t("sharelinkuploadpage.savedriveof") }}
                        <span style="color: #386e50; font-weight: 600;"
                          >{{ foldername }}</span
                        >
                      </p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-text>
                  <v-layout v-if="totalstackfile > 0" row wrap>
                    <v-flex sm12 class="text-center">
                      <span style="font-size:16px">
                        {{ remark }}
                      </span>
                    </v-flex>
                    <v-flex sm1></v-flex>
                    <v-flex sm10 class="mt-4 text-center">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model.trim="filename"
                        :label="$t('sharelinkuploadpage.filename')"
                        outlined
                        dense
                        style="width:300px"
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm1></v-flex>
                  </v-layout>
                  <v-layout v-else style="height:102px"> </v-layout>
                  <v-layout style="height:40px" row wrap>
                    <v-flex sm2></v-flex>
                    <v-flex sm8 class="mt-5 text-center mb-n6">
                      <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                        <span
                          style="color: white; font-weight: 600; font-size: 14px"
                          >{{ $t("sharelinkuploadpage.status") }}:
                          {{ $t("sharelinkuploadpage.waitupload") }}</span
                        >
                      </v-chip>
                      <v-chip color="success" v-else-if="statussuccess == true">
                        <span
                          style="color: white; font-weight: 600; font-size: 14px"
                          >{{ $t("sharelinkuploadpage.status") }}:
                          {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                        >
                      </v-chip>
                      <v-chip
                        color="error"
                        v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                      >
                        <span
                          style="color: white; font-weight: 600; font-size: 14px"
                          >{{ $t("sharelinkuploadpage.status") }}:
                          {{ $t("sharelinkuploadpage.notupload") }}</span
                        >
                      </v-chip>
                      <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 14px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                    </v-flex>
                    <v-flex sm2></v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-text class="mb-n8">
                  <v-layout row wrap>
                    <v-flex sm6 class="text-right pr-6">
                      <v-btn
                        color="red"
                        height="30px"
                        width="80%"
                        outlined
                        style="font-size: 16px; font-weight: lighter;"
                        :disabled="createprogress"
                        @click="cleardata()"
                      >
                        {{ $t("sharelinkuploadpage.clearfile") }}
                      </v-btn>
                    </v-flex>
                    <v-flex sm6 class="text-left pl-6">
                      <v-btn
                        class="white--text"
                        color="#23526c"
                        style="font-size: 16px; font-weight: lighter;"
                        height="30px"
                        width="80%"
                        :disabled="totalstackfile === 0 || filename.length <= 0"
                        @click="fnUploadFileShareLink()"
                      >
                        {{ $t("sharelinkuploadpage.uploadfile") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
          <!-- จอ xs -->
          <v-card
            v-else-if="resolutionScreen === 220"
            style="margin-top: 10px; border-radius: 25px; padding-top: 10px; padding-bottom: 20px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text style="font-size: 16px" class="text-right mt-3 pl-8 mb-n8">
              <span>
                <v-icon v-if="business_shared_th === 'ไดร์ฟส่วนตัว'" size="18">mdi-weather-cloudy</v-icon>
                <v-icon v-else size="18">mdi-office-building</v-icon>
                <b> {{ $t("publicpage.sharedfrom") }}:</b> {{ $t("default") === "th" ? business_shared_th : business_shared_eng}}
              </span>
              <br>
              <span>
                <v-icon size="18">mdi-account-circle-outline</v-icon>
                <b> {{ $t("publicpage.sharedby") }}:</b> {{ $t("default") === "th" ? name_shared_th : name_shared_eng}}
              </span>
            </v-card-text>
            <v-card-text class="px-8 pt-6 mb-n12">
              <v-layout>
                <v-flex lg12>
                  <p style="font-size: 16px">
                    {{ $t("sharelinkuploadpage.savedriveof") }}
                    <span style="color: #386e50; font-weight: 600;"
                      >{{ foldername }}</span
                    >
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="totalstackfile > 0" class="mb-n6">
              <v-layout row wrap justify-center class="mx-2">
                <v-flex
                  xs12
                  class="text-center mt-12 py-11 mx-auto"
                  id="zonefile"
                >
                  <span class="my-12">
                    <v-icon
                      size="40"
                      color="error"
                      class="pl-12 ml-12 mr-n12 mt-n6"
                      @click="fnDeleteStackFile(arraydataupload[0])"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                    <br />
                    <v-icon size="100" :color="arraydataupload[0].icon[1]">
                      {{ arraydataupload[0].icon[0] }}
                    </v-icon>
                    <br />
                    <span
                      style="font-size:18px"
                      :title="arraydataupload[0].name"
                      >{{ arraydataupload[0].name | subStr }}</span
                    >
                  </span>
                </v-flex>
                <br />
                <v-flex xs12 class="mt-6 text-center">
                  <span style="font-size:16px">
                    {{ remark }}
                  </span>
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex xs10 class="mt-3 text-center">
                  <v-text-field
                    height="35px"
                    class="textFieldClass"
                    v-model.trim="filename"
                    :label="$t('sharelinkuploadpage.filename')"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs1></v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text
              v-else
              style="max-height: auto; height: auto;"
              class="mt-2"
            >
              <v-layout row wrap class="mx-2">
                <v-flex lg12>
                  <!-- upload layout -->
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto pb-0"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover" class="pt-4">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        style="display:none;"
                      />
                      <v-img
                        max-width="180"
                        max-height="160"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" style="font-size: 16px">{{
                        $t("sharelinkuploadpage.clicktoupload_mobile")
                      }}</span>
                      <br />
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size: 14px"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                      <br /><br />
                    </div>
                  </div>
                  <v-layout style="height:95px"> </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout class="mb-10" row justify-center>
                <v-flex lg5></v-flex>
                <v-flex lg2 class="text-center">
                  <v-chip color="#FF6F00" v-if="totalstackfile > 0">
                    <span
                      style="color: white; font-weight: 600; font-size: 14px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.waitupload") }}</span
                    >
                  </v-chip>
                  <v-chip color="success" v-else-if="statussuccess == true">
                    <span
                      style="color: white; font-weight: 600; font-size: 14px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploadsuccess") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="error"
                    v-else-if="percentuploadedallfile !== 100 && createprogress === false"
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 14px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.notupload") }}</span
                    >
                  </v-chip>
                  <v-chip
                    color="yellow"
                    v-else
                  >
                    <span
                      style="color: white; font-weight: 600; font-size: 14px"
                      >{{ $t("sharelinkuploadpage.status") }}:
                      {{ $t("sharelinkuploadpage.uploading") }}</span
                    >
                  </v-chip>
                </v-flex>
                <v-flex lg5></v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";
import OtpInput from "otp-input-vue2";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    phonenumber: { required },
    username: { required },
    password_login: { required },
  },
  components: {
    OtpInput,
  },
  data: function() {
    return {
      loading: false,
      loadingpage: true,
      folder_name_: "",
      folder_id_: "",
      otp: "",
      timeInterval: 0,
      countDown : 0,
      check_memory: false,
      user_id: sessionStorage.getItem("user_id"),
      checklogin_cookie: (VueCookies.get("token") == null && sessionStorage.getItem("token") === null) || VueCookies.get("username") === null ? "false" : "true",
      checkIsvalidOTP: true, 
      showpassword: false,
      password_login: "",
      username: "",
      phonenumber: "",
      refCode: "",
      tab_sms: false,
      tab_username: true,
      step1: 0, // NEW 
      username_show: "", // NEW 
      isExistProfile: false, // MAI
      isLogged: false, // MAI
      isSendOTP: false, // NEW
      isloading: false, // NEW
      isTimeout: false, // NEW
      isDisableUpload: true,
      fail_: false,
      errormessage: "",
      defaultflag: "",
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en",
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th",
        },
      ],
      userdata: {},
      checkhashtag: false,
      datahashtag: [],
      tempdatahashtag: [],
      hashtag: "",
      arraydataupload: [],
      temparraydataupload: [],
      openuploadlayout: false,
      statusuploading: false,
      totalstackfile: 0,
      createprogress: false,
      stackfileloading: false,
      statusstorage: false,
      checkorientation: false,
      filename: "",
      loading_profile: false,
      percentuploadedallfile: 0,
      statussuccess: false,
      remark: "",
      foldername: "",
      name_shared_eng: "",
      name_shared_th: "",
      business_shared_eng: "",
      business_shared_th: "",
      folderid: "",
      accesslinkerror: false,
      accessexpirederror: false,
      accessdisable: false,
      accesskeyerror: false,
      accessnolink: false,
    };
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    ...mapGetters(["dataCitizenProfile", "dataAccountId"]),
    colorVapp() {
      // return "background-color: #cce4d4;";
      return "background-color: #e8eff2; overflow-y:hidden";
    },
    //style upload layout ของ mobile
    uploadLayout() {
      if (this.createprogress === true) {
        return "border-radius: 24px; cursor: not-allowed;";
      } else {
        return "border-radius: 24px; cursor: pointer;";
      }
    },
    // percentuploadedallfile() {
    //   return (อัปโหลดเฉพาะไฟล์รูป .jpeg .png .jpg
    //     this.temparraydataupload.reduce((a, b) => {
    //       let x = b["value"] === 500 ? 100 : b["value"];
    //       let sum = a + x;
    //       return sum;
    //     }, 0) / this.temparraydataupload.length
    //   );
    // },
    totalerror() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "fail";
        }).length || 0
      );
    },
    usernameloginError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required &&
        errors.push("โปรดระบุชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordloginError() {
      const errors = [];
      if (!this.$v.password_login.$dirty) return errors;
      !this.$v.password_login.required &&
        errors.push("โปรดระบุรหัสผ่าน / Please fill Password");
      return errors;
    },
    otpclass() {
      if (this.checkIsvalidOTP === true) {
        console.log("True ");
        return "mai-classsed";
      } else {
        console.log("Falsde ");
        return "mai-classsed error-class";
      }
    },
    formattedCountdownLoginSMS() {
      const mins = ~~(this.countDown / 60);
      const secs = this.countDown % 60;
      return `${this.padTime(mins)}:${this.padTime(secs)}`;
    },
  },
  methods: {
    // เปลี่ยนภาษา
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    // กำหนดไอคอน
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    // ลบไฟล์ที่เก็บ
    fnDeleteStackFile(filedata) {
      var index = this.arraydataupload.indexOf(filedata);
      this.arraydataupload.splice(index, 1);
      this.totalstackfile = this.totalstackfile - 1;
    },
    // ลบ event
    removeEvent() {
      var self = this;
      self.closeDragDrop = true;
      window.removeEventListener("dragenter", self.dragenter, true);
      window.removeEventListener("dragleave", self.dragleave, true);
      window.removeEventListener("dragover", self.dragover, true);
      window.removeEventListener("drop", self.drop, true);
      window
        .matchMedia("(orientation: portrait)")
        .removeListener(this.fnOrientation);
    },
    // เพิ่ม event dragdrop
    addEvent() {
      if (this.resolutionScreen >= 500) {
        // ######### เช็คว่าหน้าจอเล็กไม่ให้ drag drop ได้ #########
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenter, true);
        window.addEventListener("dragleave", self.dragleave, true);
        window.addEventListener("dragover", self.dragover, true);
        window.addEventListener("drop", self.drop, true);
      }
    },
    // เพิ่ม event Oriention
    addEventOrientation() {
      window
        .matchMedia("(orientation: portrait)")
        .addListener(this.fnOrientation);
      let height = window.screen.height;
      let width = window.screen.width;
      if (height > width) {
        this.checkorientation = false;
      } else {
        this.checkorientation = true;
      }
    },
    fnOrientation(e) {
      if (e.matches) {
        this.checkorientation = false;
      } else {
        this.checkorientation = true;
      }
    },
    // ลากเข้าไปโซน
    dragenter(e) {
      if(e.target.classList.contains('areadrag') && this.totalstackfile === 0){
        if (this.createprogress === false) {
          this.isDisableUpload = false
          document.querySelector("#dropzone").style.border = "4px dashed #448060";
          document.querySelector("#dropzone").style.background = "white";
          document.querySelector("#layouthover").style.background = "#aeb3b5";
          document.querySelector("#layouthover").style.opacity = "0.4";
        }
      }
    },
    // ลากเข้าเช็คทุกๆ 200 milliseconds
    dragover(e) {
      if(e.target.classList.contains('areadrag') && this.totalstackfile === 0){
      if (this.createprogress === false) {
        e.preventDefault();
        e.stopPropagation();
        this.isDisableUpload = false
        document.querySelector("#dropzone").style.border = "4px dashed #448060";
        document.querySelector("#dropzone").style.background = "white";
        document.querySelector("#layouthover").style.background = "#aeb3b5";
        document.querySelector("#layouthover").style.opacity = "0.4";
      }
      }
    },
    // ลากออก
    dragleave(e) {
      try{
        if(e.fromElement.classList.contains('areadrag') === false ||
            e.fromElement === null){
            if (this.createprogress === false) {
            e.preventDefault();
            e.stopPropagation();
            this.isDisableUpload = true
            document.querySelector("#dropzone").style.border = "4px dashed #5fb588";
            document.querySelector("#dropzone").style.background = "#e8eff2";
            document.querySelector("#layouthover").style.background = "#e8eff2";
            document.querySelector("#layouthover").style.opacity = "1";
          } 
        } else {
          return
        }
      } catch (err) {
        console.log("DRAG LEAVE ERROR")
        if(this.totalstackfile === 0) {
          if (this.createprogress === false) {
              e.preventDefault();
              e.stopPropagation();
              this.isDisableUpload = true
              document.querySelector("#dropzone").style.border = "4px dashed #5fb588";
              document.querySelector("#dropzone").style.background = "#e8eff2";
              document.querySelector("#layouthover").style.background = "#e8eff2";
              document.querySelector("#layouthover").style.opacity = "1";
          }     
      }
      }
    },
    // ลากแล้วปล่อยไฟล์
    async drop(e) {
      console.log("e.dataTransfer", e.dataTransfer.files);
      if(this.isDisableUpload){
        return
      } else { 
      if (this.createprogress === false) {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector("#dropzone").style.border = "4px dashed #5fb588";
        document.querySelector("#dropzone").style.background = "#e8eff2";
        document.querySelector("#layouthover").style.background = "#e8eff2";
        document.querySelector("#layouthover").style.opacity = "1";
        if (e.dataTransfer.files.length === 1) {
          let items = e.dataTransfer;
          this.fnStackFileDrug(items.files);
        } else {
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkuploadpage.notfilemoreone"),
          });
        }
      }
      }
    },
    // เด้งให้เลือกไฟล์
    ImportFiles() {
      if (this.createprogress === false) {
        this.selectingImportFiles = true;
        this.checkfirstloopchoice = false;
        this.fileImport = null;
        this.$refs.importdoc.value = null;
        window.addEventListener(
          "focus",
          () => {
            this.selectingImportFiles = false;
          },
          { once: true }
        );
        this.$refs.importdoc.click();
      }
    },
    // ลูปการเก็บไฟล์ของการเลือกไฟล์
    async fnStackFile(e) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.statusstorage = false;
      this.statussuccess = false;
      this.fileImport = e.target.files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
    },
    // ลูปการเก็บไฟล์ของการลากไฟล์
    async fnStackFileDrug(files) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.statusstorage = false;
      this.statussuccess = false;
      this.fileImport = files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        if (this.fileImport[i].type !== "") {
          let formData = await this.createPayloadUpload(i, numcreateformdata);
          this.arraydataupload[i].formData = formData;
          this.totalstackfile = this.totalstackfile + 1;
          numcreateformdata = numcreateformdata + 1;
          if (totalallfile === i + 1) {
            this.stackfileloading = false;
          }
        }
      }
    },
    // สร้าง payload สำหรับอัปโหลด
    async createPayloadUpload(i, numcreateformdata) {
      let typefile;
      if (!this.arraydataupload[i]) {
        // ปั้นดาต้า
        typefile = this.fileImport[numcreateformdata].name.split(".");
        typefile = typefile[typefile.length - 1];
        let iconfile = this.seticon(typefile);

        let datafileupload = {};
        datafileupload["file"] = this.fileImport[numcreateformdata];
        datafileupload["icon"] = iconfile;
        datafileupload["name"] = this.fileImport[numcreateformdata].name;
        datafileupload["size"] = this.fileImport[numcreateformdata].size;
        datafileupload["value"] = 0;
        datafileupload["status"] = "start";
        datafileupload["msg"] = "";
        datafileupload["colorupload"] = "primary";
        datafileupload["fileduplicate"] = "";
        datafileupload["formData"] = "";
        datafileupload["type"] = typefile;
        this.arraydataupload.push(datafileupload);
      }
    },
    async fnUploadFileShareLink() {
      this.statussuccess= false;
      this.totalstackfile = 0;
      this.createprogress = true;
      this.temparraydataupload = [...this.arraydataupload];
      this.tempdatahashtag = [...this.datahashtag];
      this.checkhashtag = false;
      this.arraydataupload = [];
      const formData = new FormData();
      formData.append("data_id", this.folderid );
      formData.append("file", this.temparraydataupload[0].file);
      formData.append("account_id", this.dataAccountId);
      formData.append("filename", this.filename);
      let auth = await gbfGenerate.generateToken();
      let result = await this.axios({
        method: "POST",
        url:
          process.env.VUE_APP_SERVICE_UPLOAD_API +
          "/api/upload/share_data_link_upload",
        data: formData,
        headers: { Authorization: auth.code },
        onUploadProgress: (e) => {
          if ((e.loaded / e.total) * 100 >= 95) {
            this.temparraydataupload[0]["value"] = 95;
          } else {
            this.temparraydataupload[0]["value"] = parseInt(
              (e.loaded / e.total) * 100
            );
          }
        },
      });
      try {
        if (result.data.status === "OK") {
          this.statussuccess = true;
          this.createprogress = false;
          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkuploadpage.uploadsuccess"),
          });
        } else {
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkuploadpage.failuplaod"),
          });
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    //เคลียร์ข้อมูล
    async cleardata() {
      this.selectingimportFiles = false;
      this.fileImport = null;
      this.arraydataupload = [];
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.totalstackfile = 0;
      this.statusstorage = false;
      this.datahashtag = [];
      this.tempdatahashtag = [];
      this.filename = "";
      this.percentuploadedallfile = 0;
      this.statussuccess = false;
      // this.business_shared_eng = "";
      // this.business_shared_th = "";
      // this.name_shared_th = "";
      // this.name_shared_eng= "";
      // this.remark = "";
      // this.folderid = "";
    },
    async fnGetDataSharelinkUpload() {
      let payload = {
        link: this.$route.query.id
      }
      let auth = await gbfGenerate.generateToken();
      let response = await this.axios({
        method: "POST",
        url:
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/get_shared_link_upload",
        data: payload,
        headers: { Authorization: auth.code },
      });
      try {
        if (response.data.status === "OK") {
          this.business_shared_eng = response.data.data.business_shared_eng;
          this.business_shared_th = response.data.data.business_shared_th;
          this.name_shared_th = response.data.data.name_shared_th;
          this.name_shared_eng= response.data.data.name_shared_eng;
          this.foldername = response.data.data.data_name;
          this.remark = response.data.data.remark;
          this.openuploadlayout = true;
          this.createprogress = false;
          this.folderid = response.data.data.data_id;
        } else {
          this.accesslinkerror = true;
          if (response.data.errorMessage === "Please input link" && response.data.errorCode === "ER404") {
            this.accessdisable = true;
          } else if (response.data.errorMessage === "link was expired" && response.data.errorCode === "ER403") {
            this.accessexpirederror = true;
          } else if (response.data.errorMessage === "This link was disable" && response.data.errorCode === "ER403") {
            this.accessdisable = true;
          } else if (response.data.errorMessage === "Key(s) missing (link)" && response.data.errorCode === "ER404") {
            this.accessdisable = true;
          } else {
            this.accesskeyerror = true;
          }
        }
      } catch (err) {
        Toast.fire({
          icon: "error",
          title: err,
        });
      }
    },
    // ล็อกอิน
    async fn_clicktabinfor () {
      clearInterval(this.timeInterval)
      this.isSendOTP = false
      this.isTimeout = false
      this.tab_username = true
      this.tab_sms = false
      this.otp = ""
      this.refCode = ""
      this.phonenumber = ""
    },
    async fn_clicktabphone () {
      this.tab_username = false
      this.tab_sms = true
      this.username = ""
      this.password_login = ""
      this.$v.username.$reset()
      this.$v.password_login.$reset()
    },
    async fn_sendOTP() {
      //check validate
      if (this.$v.phonenumber.$invalid && this.tab_sms) {
        this.$v.$touch();
        return
      }

      let payload = {
        mobile_no: this.phonenumber,
      };
      // console.log("payload mobile_no", payload);
      let auth = await gbfGenerate.generateToken();      
      this.axios
        .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/oauth_otp_login", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          if (res.data.status == "OK") {
            this.isTimeout = false
            clearInterval(this.timeInterval)
            this.refCode = res.data.data.mobile_ref_id
            this.isSendOTP = true
            this.countDownTimer();
          } else if (res.data.errorMessage == "too many requests.") {
            console.log("error too many requests.", res.data);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.tooManyRequests"),
            });
          } else {
            console.log("error Mobile_no not found", res.data);
            Toast.fire({
              icon: "error",
              title: this.$t('toast.mobileNumberIncorrect'),
            });
            // this.modelValue = '0';
          }
        })
        .catch((err) => {
          console.log('err', err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          // this.modelValue = '0';
        });
    },
    async fn_resendOTP () {
      if(this.isTimeout){
        this.fn_sendOTP()
      }
    },
    onCompleteOTP(otp) {
      this.otp = otp
    },
    onChangeOTP(otp) {
      this.otp = otp
    },
    fn_loginshare () {
      if (this.user_id !== "" && this.user_id !== null) {
        this.user_id = CryptoJS.AES.decrypt(this.user_id, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
        this.user_id = window.atob(this.user_id.toString(CryptoJS.enc.Utf8));
      }
      if(this.username === "" && this.password_login === "" && !this.tab_sms && !this.isExistProfile){
        console.log('ผ่านไไไ')
        this.$v.password_login.$dirty
        return
      }
      this.isloading = true
      if (this.checklogin_cookie == "true") {
        console.log("เข้านี้ ")
        this.check_memory = true;
        this.$store
          .dispatch("authorize_checking", this.user_id)
          .then((msg) => {
            // console.log(msg);
            this.loading = false;
            if (msg.status === "Access Granted") {
              console.log("this.check_button_login", this.check_button_login);
              if (this.check_memory == false) {
                let userid = VueCookies.get("user_id");
                // let userid = localStorage.getItem("user_id");
                let token = VueCookies.get("token");
                sessionStorage.setItem("user_id", userid);
                sessionStorage.setItem("checklogin", false);
                sessionStorage.setItem("token", token, 60 * 60 * 24);
                sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
                localStorage.removeItem("user_id");
                VueCookies.set("checklogin", false, 60 * 60 * 24);
              } else {
                VueCookies.set("checklogin", true, 60 * 60 * 24);
                // VueCookies.set("user_en", encodeduserpass, 60 * 60 * 24);
              }
              this.isloading = false
              // จอนใส่ต่อตรงนี้ VVV
              this.fnGetDataSharelinkUpload();
              this.addEvent(); // AD EVENT DRAG DROP

            
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t('landingpage.notiloginandregister.incorrectusernameorpassword')
            });
            this.loader = false;
          } else if(msg.msg === "ER401: The resource owner or authorization server denied the"){
            Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t('toast.expired_token')
              });
              this.logout();

          }else {
            console.log("เข้าอันนี้");
              Toast.fire({ 
                icon: "error",
                // title: msg.msg
                // title: this.$t('toast.cannotconnectonebox')
                title: this.$t("toast.expired_token"),
              });
              this.logout();
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ "
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });
      } else {
        let payload;
        VueCookies.set("check_btn_login_new", true);
        let apiPathLogin = 'authorize_login_nooneid_shareupload_dedicate'
        let loginType = "username"
        if (this.tab_username) {
          VueCookies.set("check_mohpromt", false);
          console.log("เข้า1");
          let usernamepass = {
            username: this.username,
            password: this.password_login,
            login_type: "username",
          };
          var encodeduserpass = btoa(JSON.stringify(usernamepass));
          if (
            VueCookies.get("user_en") === null ||
            VueCookies.get("user_en") === ""
          ) {
            payload = {
              data: encodeduserpass,
            };
          } else {
            payload = {
              data: VueCookies.get("user_en"),
            };
          }
          // decode กลับ
          var actual = JSON.parse(atob(encodeduserpass));
          console.log("actual", actual);
        }
        if (this.tab_sms) {
          payload = {
            mobile_no: this.phonenumber,
            otp: this.otp,
          };
          console.log("payload SMS", payload);
          apiPathLogin = 'authorize_login_mobile_otp'
          loginType = "sms"
        }
        this.$store
          .dispatch(apiPathLogin, payload)
          .then((msg) => {
            console.log("msg",msg);
            if (msg.status === "Access Granted") {
              // ส่ง event ไปยัง Google Analytics
              this.$gtag.event('login', {
                event_category: loginType,
              });
              
              clearInterval(this.timeInterval)
              // localStorage.setItem("loginType", loginType);
              VueCookies.set("loginType", loginType);
              console.log("this.check_button_login", this.check_button_login);
              //  ล้อตาม login เดิม แบบ "ไม่จดจำฉัน"
              // let userid = localStorage.getItem("user_id");
              let userid = VueCookies.get("user_id");
              let token = VueCookies.get("token");
              sessionStorage.setItem("user_id", userid);
              sessionStorage.setItem("checklogin", false);
              sessionStorage.setItem("token", token, 60 * 60 * 24);
              sessionStorage.setItem("token_auth", true, 60 * 60 * 24);
              VueCookies.set("checklogin", false, 60 * 60 * 24);
              // จอนใส่ต่อตรงนี้ VVV
              this.fnGetDataSharelinkUpload();
              this.addEvent(); // AD EVENT DRAG DROP
            

            } else if (msg.code === "LN001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.code === "ER401") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.msg === "ER401: Authorization is wrong") {
              Toast.fire({
                icon: "error",
                title: this.$t(
                  "landingpage.notiloginandregister.incorrectusernameorpassword"
                ),
              });
            } else if (msg.code === "ER001") {
              Toast.fire({
                icon: "error",
                // title: "Username หรือ Password ไม่ถูกต้อง"
                title: this.$t(
                  "landingpage.notiloginandregister.emptyusernameorpassword"
                ),
              });
            } else if (msg.code === "ER002") {              
              Toast.fire({
                icon: "error",
                title: this.$t("toast.otpMismatch"),
              });
            } else if (msg.code === "ER304") {
              Toast.fire({
                icon: "error",
                title: this.$t("landingpage.notiloginandregister.incorrectlogin"),
              });
            }else if(msg.code === "ER105"){
              Toast.fire({
                icon: "error",
                title: this.$t("otp_botonebox.invalid_otp"),
              });
            }else{
              Toast.fire({
                icon: "error",
                // title: msg.msg
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
            this.isloading = false
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loading = false;
          });        
      }
    },
    getProfileuser () {
      // let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      // let token_ = token ? window.atob(token) : "";
        this.$store
        .dispatch('authorize_checking')
        .then((msg) => {
          // this.loading = false
          this.loadingpage = false
          console.log("Message denied ", msg)
          if (msg.status === "Access Denied") {
            this.logout()
            this.isExistProfile = false
          } else {
            // this.getfiledetail_v_shareupload()
            this.isExistProfile = true
            if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
              this.username_show = CryptoJS.AES.decrypt(VueCookies.get("username"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
              this.username_show = window.atob(this.username_show.toString(CryptoJS.enc.Utf8));
            } else {
              this.logout()
            }
          }
        })


    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
    },
    fn_logined () {
      // จะเด้งเข้า direct upload
      this.isLogged = true
    },
    padTime(time) { 
      //ถ้าเวลาเป็นเลขหลักเดียว จะเติม 0 ข้างหน้า
      return (time < 10 ? '0' : '') + time;
    },
    countDownTimer() {
      this.countDown = 5 * 2;
      this.timeInterval = setInterval(() => {
        if (this.countDown === 0) {
          this.isTimeout = true
          clearInterval(this.timeInterval)
        } else {
          this.countDown--
        }
      }, 1000)
    },
    checkLogoutAccount () {
      if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && VueCookies.get("username") !== undefined) {
        this.logout()
      }    
    },
    
    fn_logout () {
      this.checkLogoutAccount()
      // this.$store
      // .dispatch("authorize_expired")
      // .then((msg) => {
      //   console.log("Message Logout ", msg)
      //   this.isExistProfile = false
      // })
    },
    logout() {
      // this.$router.push({ path: "/logout_" });
      VueCookies.remove("token");
      VueCookies.remove("token_auth");
      VueCookies.remove("checklogin");
      VueCookies.remove("check_btn_login_new");
      VueCookies.remove("check_alert_email");
      sessionStorage.removeItem("biz_active");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_auth");
      sessionStorage.removeItem("checklogin");
      sessionStorage.removeItem("user_id");
      localStorage.removeItem("user_id");
      localStorage.removeItem("thai_email");
      localStorage.removeItem("username");
      // localStorage.removeItem("username_otp")
      localStorage.removeItem("token")
      VueCookies.remove("username_otp");
      VueCookies.remove("user_en");
      VueCookies.remove("continue_otp")
      VueCookies.remove("reason_otp")
      VueCookies.remove("checkpathmoppromt");
      VueCookies.remove("oneplatfrom");
      VueCookies.remove("check_citizen_oneplat");
      VueCookies.remove("feature_onlyoffice");
      VueCookies.remove("login_datetime")
      VueCookies.remove("data_id")
      VueCookies.remove("taxbox_check");
      VueCookies.remove("iconeva");
      this.checklogin_cookie = false
      this.check_memory = false
      this.showpassword = false
      this.password_login = ""
      this.username = ""
      this.phonenumber = ""
      this.tab_sms = false
      this.tab_username = true
      this.step1 = 0, // NEW 
      this.username_show = "" // NEW 
      this.isExistProfile = false // MAI
      this.isLogged = false // MAI
      this.isSendOTP = false // NEW
      this.openuploadlayout = false;
      this.removeEvent(); // REMOVE DRAG EVENT
      this.cleardata();
    },
    async getfiledetail_v_shareupload () {  // ไม่ได้ใช้งาน
      // OLD let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token = VueCookies.get("token") ? VueCookies.get("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
      let token_ = token ? window.atob(token) : "";
      // let token_ = (token = window.atob(token));
      let payload = {
        // access_token:token,
        access_token: token_,
        link: this.$route.query.id,
        password: this.password,
      };

      try {
        let auth = await gbfGenerate.generateToken();
        var response = await this.axios.post(
          // process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_shared_data_link",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
          }
        );
        if (response.data.status === "OK") {
          this.folder_name_ = response.data.data["data_name"];
        }
      }
      catch (err) {

      }

    },
  },
  async mounted() {
    // this.loginwithlogindirecupload();
    this.cleardata();
    await this.getProfileuser()
    // this.openuploadlayout = true;
  },
  created() {
    // this.addEvent();
    this.addEventOrientation();
    this.changelang(localStorage.getItem("lang"));
  },
  beforeDestroy() {
    this.removeEvent();
    this.cleardata();
    this.createprogress = false;
  },
};
</script>
<style scoped>
.drag-selector {
  padding: 15px;
}
.drag-selector__item {
  align-items: center;
}
template td {
  padding: 0px;
  margin: 0px;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#layouthover {
  background: #e8eff2;
  border-radius: 25px;
  transition: background 200ms, opacity 200ms, height 175ms, width 175ms,
    border 300ms;
  opacity: 1;
  height: 305px;
}
#layouthovermobile {
  background: #e8eff2;
  border-radius: 25px;
  transition: background 200ms, opacity 200ms, height 175ms, width 175ms,
    border 300ms;
  opacity: 1;
  height: 240px;
}

div#dropzone {
  border: 4px dashed #5fb588;
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
  background: #e8eff2;
}
#zonefile {
  border: 4px dashed #5fb588;
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
  border-radius: 24px;
  background: #e8eff2;
}

#inputfile {
  border-radius: 23px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

#titleTextUploadtitle {
  color: black;
  font-size: 18px;
  line-height: 24px;
}
#titleTextUploadsubtitle {
  color: red;
  font-size: 16px;
  line-height: 24px;
}
.checkboxStyle .v-label {
  font-size: 14px;
  color: black;
}
.textFieldClass .v-label {
  font-size: 14px;
  display: flex !important;
}
.vue-otp-input >>> .mai-classsed {
  text-align: center;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(128, 128, 128, 0.7);
  /*  */
}
.vue-otp-input >>> .error-class {
  border: solid 2px rgba(255, 125, 125, 255);
}
/* SCREEN SIZE XS  */
@media screen and (max-width: 350px) {
  .vue-otp-input >>> .mai-classsed {
    width: 30px;
    height: 35px;
  }
}
@media (min-width: 350px) and (max-width: 370px) {
  .vue-otp-input >>> .mai-classsed {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 370px) and (max-width: 385px) {
  .vue-otp-input >>> .mai-classsed {
    width: 38px;
    height: 35px;
  }
}

@media screen and (min-width: 600px) {
  .vue-otp-input >>> .mai-classsed {
    width: 48px;
    height: 40px;
  }
}
</style>
